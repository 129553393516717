<template>
  <f7-page name="itinerary">

    <!-- Page content-->

    <div class="block-title block-title-large">Thursday, Aug 5th</div>
    
    <div class="timeline">
      <div class="timeline-item">
        <div class="timeline-item-date">6pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Meet at Wall Drug</div>
            <!-- TODO: add link in card header to "Wall Drug" -->
          <div class="card-content card-content-padding">
            Caravan meeting point to kick off vacation!<br>
            <br>
            Hours: 8am to 8pm.<br> 
            Restaurant closes at 7:15pm.
          </div>
          <div class="card-footer">510 Main Street Wall, SD • 57790-0401</div>
        </div>
      </div>
      
      <div class="timeline-item">
        <div class="timeline-item-date">Evening</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Hike in Badlands National Park</div>
          <!-- TODO: add link in card header to "Badlands National Park" -->
          <div class="card-content card-content-padding">
            Badlands National Park is open 24 hours/day.<br>
            <br>
            Entry covered by the America The Beautiful Pass:
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>2 Annual Senior Passes needed. Mom & Dad must drive vehicles into park.</li>
              <li>Cost for pass is $4 per person. Pay Mom & Dad.</li>
              <li>Passes purchased at Badlands National Park.</li>
              </ul>
            <br>
            Hikes:
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Window Trail</li>
              (Beginner - .25 mile out & back - 15 min)
              <li>Door Trail</li>
              (Beginner - 1 mile out & back - 30 min)
              <li>Notch Trail</li> 
              (Intermediate - 1.33 miles out & back - 45 min)
              </ul>
              <br>
            Bring a flashlight. 
          </div>
          <div class="card-footer">Badlands Loop Road (SD 240), Badlands National Park, Interior, SD 57750</div>
        </div>
      </div>
      
      <div class="timeline-item">
        <div class="timeline-item-date">After Badlands National Park</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Best Western Golden Spike Inns & Suites</div>
          <!-- TODO: add link in card header to "Best Western Golden Spike Inn & Suites" -->
          <div class="card-content card-content-padding">
           <i>2 night stay, compliments of Mom & Dad</i><br>
            <br>
            Amenities: 
            <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Indoor and Outdoor Pool</li>
              <li>Complimentary Breakfast from 6:30 - 9:30am</li>
              <li>Guest Laundry for a fee</li>
            </ul>
          </div>
          <div class="card-footer">601 E Main Street Hill City, South Dakota 57745</div>
        </div>
      </div>
    </div>

    <div class="block-title block-title-large">Friday, Aug 6th</div>
    <div class="timeline">
      <div class="timeline-item">
        <div class="timeline-item-date">9am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Visit Bear Country USA</div>
           <!-- TODO: add link in card header to "Bear Country USA" -->
          <div class="card-content card-content-padding">
          Hours: 8am - 6pm.<br>
           <br>
           Cost:
            <!-- TODO: remove space at the top of this list -->
           <ul>
           <li>Adult $18</li>
            <li>Child (5-12) $12</li>
            <li>Senior $16</li>
            <li><i>Maximum of $65/car</i></li>
            </ul>
            <br>
            Rules:
            <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Windows must be closed at all times.</li>
              <li>Do not feed the animals.</li>
              <li>Everyone monitor mom XD</li>
            </ul>
          </div>
          <div class="card-footer">13820 S. Highway 16 – Rapid City, SD 57702</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">After Bear Country USA</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Lunch</div>
          <div class="card-content card-content-padding">
            TBD
          </div>
          <div class="card-footer">TBD</div>
        </div>
      </div>
      
      <div class="timeline-item">
        <div class="timeline-item-date">3:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Visit Mt Rushmore</div>
           <!-- TODO: add link in card header to "Mt RUshmore" -->
          <div class="card-content card-content-padding">
            Hours: 9am - 9pm<br>
            <br>
            Cost:
            <!-- TODO: remove space at the top of this list -->
            <ul>
            <li>$10/car</li>
            <li>$5/car senior</li>
            </ul>
          </div>
          <div class="card-footer">Keystone, South Dakota, South Dakota Highway 244 and U. S. Highway 16A</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">After Mt Rushmore</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Option 1</div>
          <div class="card-content card-content-padding">
            Crazy Horse Memorial<br>
             <!-- TODO: add link in card header to "Crazy Horse Memorial" -->
            <br>
            Dinner TBD<br>
            <br>
            Approximate 30 min drive<br>
            <br>
            $35/car<br>
            <br>
            Open until 30 minutes after laser light show -
            Laser light show starts at 9:30pm.<br>
            <br>
            Return time to hotel is 20 minutes
          </div>
          <div class="card-footer">12151 Avenue of the Chiefs, Crazy Horse, SD 57730</div>
        </div>
        <div class="timeline-item-content card">
          <div class="card-header">Option 2</div>
          <div class="card-content card-content-padding">
            Deadwood<br>
             <!-- TODO: add link in card header to "Deadwood" -->
            <br>
            <br>
            Dinner TBD<br>
            <br>
            Approximate 1 hour drive<br>
            <br>
            Costs and hours vary based on activity<br>
            <br>
            Return time to hotel is 1 hour
          </div>
          <div class="card-footer">Deadwood, South Dakota</div>
          </div>
      </div>
    </div>

    <div class="block-title block-title-large">Saturday, August 7th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">Depart 7am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Yellowstone National Park</div>
          <div class="card-content card-content-padding">
            Check-out of Best Western<br>
            <br>
            Approximate 9 hour drive, does not include stop for dinner<br>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

    <div class="timeline-item">
        <div class="timeline-item-date">4pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Caravan Meetup in Cody</div>
          <div class="card-content card-content-padding">
          Dinner TBD<br>
          <br>
          After dinner, shop at Walmart for lunches/snacks in Yellowstone.<br>
          <br>
          At 5pm, continue on to Yellowstone National Park. Approximate 1 hour drive to entrance. 
          </div>
          <div class="card-footer">Cody, WY</div>
        </div>
      </div>

<div class="timeline-item">
        <div class="timeline-item-date">6pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Arrive At East Entrance of Yellowstone</div>
          <div class="card-content card-content-padding">
            Entry fee covered by America the Beautiful Pass. Mom & Dad must be drivers.<br>
          </div>
          <div class="card-footer">N Fork Hwy, Yellowstone National Park, WY 82190</div>
        </div>
      </div>

 <div class="timeline-item">
        <div class="timeline-item-date">7pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Hayden Valley Overlook</div>
          <div class="card-content card-content-padding">
            Stop at Overlook to watch the bison herd.
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>


      <div class="timeline-item">
        <div class="timeline-item-date">7:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Check-in to Canyon Lodge & Cabins</div>
          <!-- TODO: add link in card header to "Canyon Lodge & Cabins" --> 
          <div class="card-content card-content-padding">
           <i>2 night stay, compliments of Mom & Dad</i> <br>
           <br>
            Hotel Amenities:
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>No TVs or A/C. Limited Wifi.</li>
              <li>Mini fridge & coffe maker in room.</li>
            </ul>
            <br>
            Canyon Lodge Eatery:
             <!-- TODO: remove space at the top of this list -->
           <ul>
              <li>Dine-in Hours: Breakfast 6:30am - 10am ; Lunch 11:30am - 3:00pm ; Dinner 4:30pm - 9:30pm.</li>
              <li>To go hours: 6:30am - 9:30pm.</li>
            </ul>  
            <br>
            Canyon Lodge Falls Cafe:
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Sells only beer & wine.</li>
              <li>Open 12pm - 9pm</li>
            </ul>  
            Closed due to Covid:
            <!-- TODO: remove space at the top of this list -->
             <ul>
              <li>The Ice Creamery</li>
              <li>M66 Grill</li>
              <li>Washburn Lookout</li>
            </ul> 
          </div>
          <div class="card-footer">Canyon Lodge, 41 Clover Ln, Yellowstone National Park, WY 82190</div>
        </div>
      </div>

      
    </div>


<div class="block-title block-title-large">Sunday, August 8th</div>
    <div class="timeline">

 <div class="timeline-item">
        <div class="timeline-item-date">7am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Old Faithful Area</div>
          <div class="card-content card-content-padding">
            Approximate 1 hour drive to Old Faithful<br>
            <br>
            Bring:
            <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Face mask</li>
              <li>Whatever you need for hiking</li>
              <li>Water</li>
              <li>Sack lunch (if not eating out) and hearty afternoon snack (can be purchased at Old Faithful Inn</li>
              <li>Bug spray and bear spray</li>
              <li>Layers</li>
              <li>Hand sanitizer</li>
              <li>Binoculars, if you have them</li>
            </ul>

          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">8am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Visit Old Faithful Area</div>
          <div class="card-content card-content-padding">
          Check app for eruption times for Old Faithful- wait or come back later. She erupts about every 90 minutes.<br>
            <br>
            Hikes:<br>
            <br>
            Upper Geiser Basin Trail
           <ul>
            <li>Beginner - 5.1 miles out & back - 2-3hour</li>
            <li>Turn back where you like.</li>
            <li>Use App for self-guided audio tour.</li>
            </ul>
          Observation Point
          <ul>
            <li>Intermediate - 1.5 miles out & back - 1 hour - check for closure due to bears</li>
            <li>Bring bear spray.</li>
            </ul>
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">11am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Picnic at Old Faithful</div>
          <div class="card-content card-content-padding">
           Meet at East Lot Picnic Grounds<br>
           <br>
           Meal Options:
            <!-- TODO: remove space at the top of this list -->
           <ul>
            <li>Sack meal</li>
            <li>Old Faithful Lodge Cafeteria & Deli (take-out only)</li>
            Closed 10:30-11am<br>
            <li>Old Faithful Inn Bear Paw Deli (take-out only)</li>
            </ul>
          </div>
          <div class="card-footer">East Lot</div>
        </div>
      </div>

  <div class="timeline-item">
        <div class="timeline-item-date">12pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Depart for Midway Geyser Basin Area</div>
          <div class="card-content card-content-padding">
            Hiking Options:<br>
            <br>
            Fairy Falls Trail:<br>
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Intermediate - 1.6 mile out & back to Overlook - 1 hour</li>
              <li>Park at the Fairy Falls Parking Lot</li>
              <li>Turn back at Overlook. Meet group at Midway Geyser Basin Parking Lot area.</li>
              <li>Bring bear spray and sunscreen</li>
            </ul>
           <br>
           Midway Geyser Basin Trail:<br>
             <!-- TODO: remove space at the top of this list -->
            <ul>
            <li>Beginner - .8 mile loop on boardwalk - 1 hour</li>
            <li>Park at the Midway Geyser Basin Trail</li>
            <li>Be careful/walk on boardwalks, the springs and pools are hot enough to cause 3rd degree burns and death</li>
            <li>Sights: Grand Prismatic Spring, Opal Pool, Torquise Pool, Excelsior Geyser</li><br>
            </ul>
          </div>
          <div class="card-footer">Fairy Falls parking Lot</div>
        </div>
  </div>




     <div class="timeline-item">
        <div class="timeline-item-date">2:00pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Depart for Cook-out</div>
          <div class="card-content card-content-padding">
           Approximate 1 hr 45 min drive <br>
           <br>
           Stop along the way for a hearty snack and restroom break. Options:<br>
            <!-- TODO: remove space at the top of this list -->
           <ul>
             <li>Gibbon Meadows Picnic Area</li>
             <li>Appolinarus Picnic Area</li>
             <li>Mammoth Picnic Area</li>
            </ul>
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

       <div class="timeline-item">
        <div class="timeline-item-date">4:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Yellowstone Old West Cook Out </div>
          <div class="card-content card-content-padding">
            <i>Compliments of Mom & Dad</i><br>
            <br>
          Details:
            <!-- TODO: remove space at the top of this list -->
           <ul>
              <li>Meet at Rossevelt Corrals at 4:45pm.</li>
              <li>Wagon ride takes 30 – 45 minutes and goes through sagebrush flats.</li>
              <li>The cookout begins at dusk. Buffet Menu: Steak, coleslaw, potato salad, baked beans, chuck wagon corn, corn muffins, watermelon, fruit crisp, assorted beverages, cobbler & coffee. Seconds will most likely be available.</li>
              <li>Bring bug spray, a light layer, and hand sanitizer for the pit-potty.Also bring binoculars, if you have them.</li>
              <li>Mask required on the wagon.</li>
            </ul>
             <br>
          </div>
          <div class="card-footer">Roosevelt Corrals, 105 Roosevelt Lodge Rd, Mammoth, WY 82190 .</div>
        </div>
      </div>
      
      <div class="timeline-item">
        <div class="timeline-item-date">after cookout</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Return to Canyon Lodge</div>
          <div class="card-content card-content-padding">
           Approximate 1hr 30min drive
          </div>
          <div class="card-footer">Canyon Lodge, 41 Clover Ln, Yellowstone National Park, WY 82190</div>
        </div>
      </div>
    </div>


<div class="block-title block-title-large">Monday, August 9th</div>
 <div class="timeline">

 <div class="timeline-item">
        <div class="timeline-item-date">Morning</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">See more of Yellowstone or Sleep In</div>
          <div class="card-content card-content-padding">
            Options:<br>  
            <br>
            Grand Canyon of the Yellowstone River
             <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Drive to Lookout Point. 5 minutes from Canyon Lodge. This view is good on its own.</li>
              <li>If you like, hike down the Red Rock Trail to Red Rock Point ... Intermediate, steep - .8 mile out & back - 1hour</li>
              <li>Check for closures and bring bear spray</li>
            </ul> 
            <br>
            Mud Volcano
            <!-- TODO: remove space at the top of this list -->
            <ul>
              <li>Drive to Mud Volcano Parking Lot. Approximate 30 minute drive.</li>
              <li>Mud Volcano Trail ... Beginner - .8 mile loop - 1 hour</li>
              <li>Use app for self-guided audio tour.</li>
              <li> Check for closures and bring bear spray.</li>
              </ul>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>

<div class="timeline-item">
        <div class="timeline-item-date">11am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Depart for Mammoth Hot Springs</div>
          <div class="card-content card-content-padding">
            Check-out of Canyon Lodge & Cabins<br>
             <br>
            Approximate 1 hour drive
          </div>
          <div class="card-footer">Mammoth Hot Springs Parking Lot</div>
        </div>
      </div>

 <div class="timeline-item">
        <div class="timeline-item-date">12pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Mammoth Hot Springs</div>
          <div class="card-content card-content-padding">
            Options:<br>
            <br>
            Hike the Lower Terrace
            <ul>
              <li>Beginner - 1 mile - 1 hour</li>
              <li>Use app for self-guided audio tour</li>
              <li>Bring bear spray</li>
            </ul>

            Drive the Upper Terrace
            <ul>
              <li>1.8 miles - 30 min</li>
              <li>Pull offs for views</li>
            </ul>

            Visit Fort Yellowstone
            <ul>
              <li>Approximately a one hour walk around Fort Yellowstone</li>
              <li>Use app for self-guided tou</li>
              <li>Elk often on grounds</li>
              </ul>
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

<div class="timeline-item">
        <div class="timeline-item-date">2:00pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Depart for Gardiner</div>
          <div class="card-content card-content-padding">
            Stop at the Roosevelt Arch for a Family Photo!<br>
             <br>
            Approximate 15 min drive
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

     <div class="timeline-item">
        <div class="timeline-item-date">2:20pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Lunch in Gardiner</div>
          <div class="card-content card-content-padding">
          TBD
          </div>
          <div class="card-footer">TBD</div>
        </div>
      </div>

   <div class="timeline-item">
        <div class="timeline-item-date">3:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Check-in to The Roosevelt Hotel</div>
          <div class="card-content card-content-padding">
            <i>Compliments of Mom & Dad </i><br>
            <br>
            Check-in is at 4, hopefully we can get in early.<br>
            <br>
            Hotel Amenities:
            <ul>
              <li>Morning coffee and pastries</li>
              <li>TV & Wifi</li>
              <li>In room coffee maker and mini-fridge</li>
              <li>Coin laundry</li>
              </ul>
            <br>
          </div>
          <div class="card-footer">1014 Scott Street West Gardiner, Montana 59030</div>
        </div>
      </div>
      <div class="timeline-item">
        <div class="timeline-item-date">4pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">White Water Rafting on the Yellowstone River</div>
          <div class="card-content card-content-padding">
            <i>Compliments of Mom & Dad </i><br>
            <br>
            Meet at Montana Whitewater
            <br>
           
          </div>
          <div class="card-footer">603 Scott St. Gardiner, MT 59030</div>
        </div>
      </div>

 <div class="timeline-item">
        <div class="timeline-item-date">8pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Final Dinner with Seattle Group</div>
          <div class="card-content card-content-padding">
           TBD
          </div>
          <div class="card-footer">TBD</div>
        </div>
      </div>

    </div>

  <div class="block-title block-title-large">Tuesday, August 10th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">11:00am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Yellowstone Hot Springs</div>
          <div class="card-content card-content-padding">
            Cost for All Day Pass (come & go):<br>
            Adults $18<br>
            Seniors $16<br>
            Children $5
          </div>
          <div class="card-footer">24 E Gate Rd, Gardiner, MT 59030</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">6pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Dinner</div>
          <div class="card-content card-content-padding">
            TBD
    
          </div>
          <div class="card-footer">TBD</div>
        </div>
      </div>
    </div>


    <div class="block-title block-title-large">Wednesday, August 11th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">9am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Depart for Grand Tetons</div>
          <div class="card-content card-content-padding">
            Approximate 3 hour drive<br>
            <br>
            Stop at Artists Paintpots
            <ul>
              <li>Beginner - 1 mile loop - 1 hour</li>
            </ul>
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">1pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Lunch</div>
          <div class="card-content card-content-padding">
            Leek's Marina & Pizzeria
          </div>
          <div class="card-footer">89 National Park Rd, Grand Teton National Park, WY</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">2:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Hike Jenny Lake</div>
          <div class="card-content card-content-padding">
            Beginner - 7.9 mile loop
          </div>
          <div class="card-footer"></div>
        </div>
      </div>

       <div class="timeline-item">
        <div class="timeline-item-date">4:00pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Check into Colter Bay Village</div>
          <div class="card-content card-content-padding">
          
          </div>
          <div class="card-footer"></div>
        </div>
      </div>

  <div class="timeline-item">
        <div class="timeline-item-date">4:30pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Dinner Float</div>
          <div class="card-content card-content-padding">
           4 hour senic float
          </div>
          <div class="card-footer"></div>
        </div>
      </div>

    </div>


    <div class="block-title block-title-large">Thursday, August 12th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">8am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Denver, Co</div>
          <div class="card-content card-content-padding">
            Approximate 9 hour drive
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

        <div class="timeline-item">
        <div class="timeline-item-date">6pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Check-in to Hampton Inn & Suites</div>
          <div class="card-content card-content-padding">
            Hotel Amenities:
            <ul>
              <li>TV & Wifi</li>
              <li>Breakfast</li>
              <li>Indoor Pool</li>
              <li>Shuttle to airport</li>
            </ul>
          </div>
          <div class="card-footer">4310 Airport Way Denver, Colorado 80239, USA</div>
        </div>
      </div>
    </div>

  <div class="block-title block-title-large">Friday, August 13th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">5:30am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Joe's 14er</div>
          <div class="card-content card-content-padding">
           Approximate 1 hour 30 minute drive 
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">7am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Mt Bierstadt</div>
          <div class="card-content card-content-padding">
          Hike partway with Joe 

          Wait in Georgetown for Joe
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

  <div class="timeline-item">
        <div class="timeline-item-date">2pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Return to Denver</div>
          <div class="card-content card-content-padding">
          Approximate 1 hour 30 minute drive
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>
    </div>

 <div class="block-title block-title-large">Saturday, August 14th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">6am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive to Omaha</div>
          <div class="card-content card-content-padding">
           Approximate 8 hour drive
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">3pm</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Check into Hyatt Place Omaha Downtown Market</div>
          <div class="card-content card-content-padding">
          
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>
   </div>
 
<div class="block-title block-title-large">Sunday, August 15th</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">6am</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Drive Home</div>
          <div class="card-content card-content-padding">
           Approximate 8 hour drive
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

     
   </div>
 

    <div class="block-title block-title-large">Day, Month Date</div>
    <div class="timeline">

      <div class="timeline-item">
        <div class="timeline-item-date">Time</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Activity</div>
          <div class="card-content card-content-padding">
            Special Info
            <a href="https://www.dynamobird.com" target="_blank">Link</a>
            <br>
            Cost
            <ul>
              <li>list</li>
              <li>of</li>
              <li>Things</li>
            </ul>
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>

      <div class="timeline-item">
        <div class="timeline-item-date">Time</div>
        <div class="timeline-item-divider"></div>
        <div class="timeline-item-content card">
          <div class="card-header">Activity</div>
          <div class="card-content card-content-padding">
            Special Info
            <br>
            Cost
          </div>
          <div class="card-footer">Location</div>
        </div>
      </div>
    </div>

  </f7-page>
</template>