<template>
  <f7-app v-bind="f7params" >

  <!-- Left panel with cover effect-->
  <f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Left Panel"></f7-navbar>
        <f7-block>Left panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <f7-toolbar tabbar labels bottom>
      <f7-link tab-link="#view-itinerary" tab-link-active icon-ios="f7:house_fill" icon-aurora="f7:house_fill" icon-md="material:home" text="Itinerary"></f7-link>
      <f7-link tab-link="#view-chat" icon-ios="f7:square_list_fill" icon-aurora="f7:square_list_fill" icon-md="material:view_list" text="Chat"></f7-link>
      <f7-link tab-link="#view-bears" icon-ios="f7:gear" icon-aurora="f7:gear" icon-md="material:settings" text="Bear Info"></f7-link>
      <f7-link tab-link="#view-profile" icon-ios="f7:gear" icon-aurora="f7:gear" icon-md="material:settings" text="Profile"></f7-link>
    </f7-toolbar>

    <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
    <f7-view id="view-itinerary" main tab tab-active url="/"></f7-view>

    <!-- Catalog View -->
    <f7-view id="view-chat" name="chat" tab url="/catalog"></f7-view>

    <!-- Settings View -->
    <f7-view id="view-bears" name="bears" tab url="/bears"></f7-view>

    <!-- Settings View -->
    <f7-view id="view-profile" name="profile" tab url="/profile"></f7-view>

  </f7-views>
  </f7-app>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { f7, f7ready } from 'framework7-vue';


  import routes from '../js/routes.js';
  import store from '../js/store';

  export default {
    setup() {

      // Framework7 Parameters
      const f7params = {
        name: 'Itinerary', // App name
        theme: 'aurora', // theme
        
        // App store
        store: store,
        // App routes
        routes: routes,
        // Register service worker
        // serviceWorker: {
        //   path: '/service-worker.js',
        // },
      };
      // Login screen data
      const username = ref('');
      const password = ref('');

      const alertLoginData = () => {
        f7.dialog.alert('Username: ' + username.value + '<br>Password: ' + password.value, () => {
          f7.loginScreen.close();
        });
      }
      onMounted(() => {
        f7ready(() => {


          // Call F7 APIs here
        });
      });

      return {
        f7params,
        username,
        password,
        alertLoginData
      }
    }
  }
</script>